<template>
  <el-dialog
    :visible.sync="show"
    width="560px"
    top="14%"
    :close-on-click-modal="false"
    class="product-detail-dialog dialogform"
    :title="$t('dc:新增产品')"
  >
    <el-form
      style="margin: 0 auto"
      label-position="right"
      label-width="120px"
      :inline="false"
      ref="formData"
      :model="formData"
      :rules="rules"
    >
      <el-form-item :label="$t('dc:产品名称')" prop="projectName" class="" required>
        <el-input
          :class="{ redFont: !showProductName }"
          v-model="formData.projectName"
          maxlength="100"
          :placeholder="$t('dc:请输入')"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('dc:编码')" prop="projectCode" class="" required>
        <el-input
          :class="{ redFont: !showProductCode }"
          v-model="formData.projectCode"
          :placeholder="$t('dc:请输入')"
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item :label="$t('dc:URL')" prop="projectUrl" class="" required>
        <el-input
          type="textarea"
          :class="{ redFont: !showProductURL }"
          v-model="formData.projectUrl"
          :placeholder="$t('dc:请输入')"
          maxlength="100"
          :autosize="{ minRows: 1, maxRows: 3 }"
          resize="none"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item :label="$t('dc:描述')" prop="introduction">
        <el-input
          type="textarea"
          :class="{ redFont: !showProductURL }"
          v-model="formData.introduction"
          :placeholder="$t('dc:请输入')"
          maxlength="200"
          :autosize="{ minRows: 4, maxRows: 4 }"
          resize="none"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button class="" @click="show = false">{{ $t('dc:取消') }}</el-button>
      <el-button class="btn-black" type="info" @click="submitForm('formData')">{{
        $t('dc:确认')
      }}</el-button>
    </span>
    <el-dialog
      width="30%"
      :title="title"
      :visible.sync="innerVisible"
      class="acctount-detail-dialog"
      :append-to-body="true"
    >
      <div class="fontW600">
        {{ $t('dc:您确认注册这个产品吗?') }}
      </div>
      <div slot="footer">
        <el-button class="" @click="innerVisible = false">{{ $t('dc:否') }}</el-button>
        <el-button class="btn-black" type="info" @click="addNewApp">{{ $t('dc:是') }}</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import confromProductDialog from '@/views/dialog/usuallyDialog'

export default {
  name: 'productDialog',
  components: {
    confromProductDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: String,
    typeOptions: {
      type: Array,
      default: () => [],
    },
    submitData: {
      type: Array,
      default: () => [],
    },
    max: Number,
  },
  data() {
    var productNameRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showProductName = false
      } else {
        this.showProductName = true
        callback()
      }
      // else if (!new RegExp("^([A-Z][a-z]*[ ]?)+\\S$").test(value)) {
      //   this.showProductName = false;
      //   callback(new Error(rule.message || this.$t('dc:请按规则输入')));
      // }
    }
    var productCodeRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showProductCode = false
      } else {
        this.showProductCode = true
        callback()
      }
      // else if (!new RegExp("^([a-z]+[_]?)+(?<!_)$").test(value)) {
      //   this.showProductCode = false;
      //   callback(new Error(rule.message || "The Products Code is existed"));
      // }
    }
    var productURLRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showProductURL = false
      } else if (
        !new RegExp(
          '^https?:\\/\\/(([a-zA-Z0-9_-])+(\\.)?)*(:\\d+)?(\\/((\\.)?(\\?)?=?&?[a-zA-Z0-9_-](\\?)?)*)*$',
        ).test(value)
      ) {
        this.showProductURL = false
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        this.showProductURL = true
        callback()
      }
    }
    var IntroDuctionRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showProductName = false
      } else if (true) {
        this.showProductName = false
        callback(new Error(rule.message || this.$t('dc:请按规则输入')))
      } else {
        this.showProductName = true
        callback()
      }
    }
    return {
      loading: false,
      show: this.visible,
      innerVisible: false,
      showProductName: true,
      showProductCode: true,
      showProductURL: true,
      currentTypeConfig: {},
      title: this.$t('dc:提醒'),
      formData: {
        projectName: '',
        projectCode: '',
        projectUrl: '',
        introduction: '',
      },
      rules: {
        projectName: [
          {
            validator: productNameRoute,
            message: this.$t('dc:请按规则输入'),
            trigger: ['blur', 'change'],
          },
        ],
        projectCode: [{ validator: productCodeRoute, trigger: ['blur', 'change'] }],
        projectUrl: [{ validator: productURLRoute, trigger: ['blur', 'change'] }],
        introduction: [{ required: true, message: this.$t('dc:请按规则输入') }],
      },
    }
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.innerVisible = true
        } else {
          return false
        }
      })
    },
    async addNewApp() {
      let { status, data, msg } = await $Api.project.addApp(this.formData)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$emit('queryApi')
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
      } else {
        this.show = false
        this.innerVisible = false
      }
    },
  },
}
</script>
<style lang="scss">
.product-detail-dialog {
  .el-form-item__label {
    font-size: 14px;
  }
}
.fontW600 {
  font-weight: 600;
  color: #000;
}
</style>
